import { createContext, useEffect, useState } from 'react'
import { parseCookies } from 'nookies'

export const SitesContext = createContext()

const SitesContextProvider = ({ sites, children, siteSlug }) => {
  const cookies = parseCookies()
  const cookieSiteId =
    cookies && cookies.siteId ? parseInt(cookies.siteId, 10) : null
  const state = {
    sites,
    currentSite: sites
      ? sites.find((s) => {
          if (!siteSlug) return parseInt(s.id, 10) === cookieSiteId

          const pathParts = s.path.split('/').filter((p) => p !== '')
          return pathParts[pathParts.length - 1] === siteSlug
        })
      : null,
  }

  return <SitesContext.Provider value={state}>{children}</SitesContext.Provider>
}

export default SitesContextProvider
