import { useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Link from 'next/link'

import generateRoute from 'helpers/generateRoute'
import { SitesContext } from 'context/SitesContextProvider'

import s from './Link.module.css'

function LinkInternal({
  href: initialHref,
  label,
  children,
  className,
  type,
  site = null,
}) {
  const { currentSite } = useContext(SitesContext)
  const { href, as } = generateRoute(
    { type, slug: initialHref },
    site || currentSite ? currentSite.path.replace(/\//g, '') : null
  )

  return (
    <Link href={href} as={as}>
      <a className={classNames(s.container, className)}>{children || label}</a>
    </Link>
  )
}

LinkInternal.propTypes = {
  href: PropTypes.string,
  as: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
}
LinkInternal.defaultProps = {
  href: '',
  as: '',
  label: null,
  className: '',
  type: null,
}

export default LinkInternal
