const cleanSlug = (slug) => slug.replace(/^\/|\/$/g, '')

const generateRoute = (link, pathPrefix = '') => {
  const slug = cleanSlug(link.slug)
  switch (link.type) {
    case 'Model':
      return {
        href: `/[site]/model/[modelSlug]`,
        as: `/${pathPrefix}/model/${slug}`,
      }
    case 'BoardModel': // this is expecting href="women/image" - "talent/artist" etc
      return {
        href: `/[site]/[boardType]/[boardSlug]/[modelSlug]`,
        as: `/${pathPrefix}/${slug}`,
      }
    case 'Board':
    case 'ModelBoard':
      return {
        href: `/[site]/[boardType]/[boardSlug]`,
        as: `/${pathPrefix}/${slug}`,
      }
    case 'Article':
    case 'Post':
      return {
        href: `/[site]/article/[slug]`,
        as: `/${pathPrefix}/article/${slug}`,
      }
    case 'Page': {
      if (slug === 'home' || slug === '')
        return {
          href: `/[site]`,
          as: `/${pathPrefix}`,
        }

      const special = ['contact', 'about', 'news']
      return {
        href: `/[site]/${special.includes(slug) ? slug : '[...slug]'}`,
        as: `/${pathPrefix}/${slug}`,
      }
    }
    case 'Site':
      return {
        href: `/[site]`,
        as: `/${slug}`,
      }
    case 'Terms': {
      return {
        href: `/[site]/[...slug]`,
        as: `/${pathPrefix}/${slug}`,
      }
    }
    // case 'Office':
    //   return {
    //     href: `/[site]/office/[slug]`,
    //     as: `${currentSite}office/`,
    //   }
    // case 'Showcase':
    //   return {
    //     href: `/[site]/showcase/[slug]`,
    //     as: `${currentSite}showcase/`,
    //   }
    case 'Category':
      return {
        href: `/[site]/news/[categorySlug]`,
        as: `/${pathPrefix}/news/${slug}`,
      }
    case 'Highlight':
      return {
        href: `/[site]/highlight/[slug]`,
        as: `/${pathPrefix}/highlight/${slug}`,
      }
    case 'CollectionItem':
      return {
        href: `/collections/[slug]/[modelSlug]`,
        as: `/collections/${slug}`,
      }
    case 'Collection':
      return {
        href: `/[site]/collections/[slug]`,
        as: `/${pathPrefix}/collections/${slug}`,
      }
    case 'Collections':
      return {
        href: `/[site]/${slug}`,
        as: `/${pathPrefix}/${slug}`,
      }
    case 'API':
      return {
        href: `/api/${slug}`,
        as: `/api/${slug}`,
      }
    default: {
      if (!slug.includes('collections'))
        console.error('Possible error: generateRoute not found type', link)
      return {
        href: `/${slug}`,
        as: `/${slug}`,
      }
    }
  }
}

export default generateRoute
